import { useIntl, Link } from "gatsby-plugin-intl"
import { defaultIntl, UseQuery, dateFormat } from "../../utils"
import Layout from "../../components/layout"
import React, { useEffect, useState, useContext } from "react"
import "../../includes/news/news.sass"
import { $get } from "../../components/forms"
import sanitizeHtml from "sanitize-html"
import { globalContext } from "../../components/context"

const cleanCode = data => {
  return sanitizeHtml(data, {
    allowedTags: false,
  })
}

export default () => {
  const intl = useIntl() || defaultIntl
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [newsId] = useState(UseQuery().get("news_id"))
  const { country } = useContext(globalContext)
  useEffect(() => {
    $get(`/news-posts/${newsId}`)
      .then(({ data }) => {
        if (data) {
          setData(data)
        }
      })
      .catch(e => {
        alert(e.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <Layout intl={intl} page="news-detail">
      <div className="news section news-detail">
        <div className="container">
          <nav className="breadcrumb has-succeeds-separator" aria-label="breadcrumbs">
            <ul>
              <li>
                <Link to={`/?country=${country}`}>
                  <strong>{intl.formatMessage({ id: "news-detail.nav.home" })}</strong>
                </Link>
              </li>
              <li>
                <Link to={`/news/?country=${country}`}>{intl.formatMessage({ id: "news-detail.nav.news" })}</Link>
              </li>
              <li className="is-active">
                <Link to="#">{intl.formatMessage({ id: "news-detail.nav.details" })}</Link>
              </li>
            </ul>
          </nav>
          {isLoading ? (
            <p>{intl.formatMessage({ id: "global.loading" })}</p>
          ) : (
            <div className="container">
              <div className="section-title">{intl.locale === 'zh' ? data.title : data[`title_${intl?.locale}`]}</div>
              <div className="section-subtitle">
                {dateFormat(data.date || data.created_at)}
              </div>
              <div className="divider"></div>
              <div
                className="news-content"
                dangerouslySetInnerHTML={{ __html: intl.locale === "zh" ? cleanCode(data.content) : cleanCode(data[`content_${intl?.locale}`]) }}
              ></div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}
